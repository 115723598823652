import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import InputMasked from "../../../../components/Input/InputMasked";
import Input from "../../../../components/Input/Input";
import Select from "react-select";
import { BsPlus } from "react-icons/bs";
import { GoTrashcan } from "react-icons/go";
import { handleAddClick, handleInputChange, handleRemoveChange } from "../../utils/InputMultiple";
import { vendemmiaOperationUnitValues } from "../../forms/vendemmiaOperationUnitValues";
import { operationTypeValues } from "../../forms/operationTypeValues";
import { useEffect } from "react";
import { useValueSelect } from "../useValueSelect";

const RegistrationDataForm = ({ values, setFieldValue, handleChange }) => {
    const [inputCompanyBranches, setInputCompanyBranches] = useState([]);

    useEffect(() => {
        if (
            values
            && values.registration
            && values.registration.companyBranches
            && values.registration.companyBranches.length > 0
        ) {
            setInputCompanyBranches(values.registration.companyBranches);
        }
    }, [values]);

    const companyOperationSelectTransformer = values => {
        return values.map(value => {
            return {
                label: value.companyOperationDocumentNumber,
                companyOperationDocumentNumber: value.companyOperationDocumentNumber,
                subscriptionState: value.subscriptionState,
            };
        });
    };

    return (
        <div>
            <div className="overflow-auto xs:h-60vh md:h-65vh xl:h-70vh w-full pb-40 pr-4">
                <div className="flex gap-4">
                    <div className="w-2/4">
                        <div className="flex gap-4">
                            <div className="w-full">
                                <label className="text-roxo_oficial font-bold mt-4">Filial Vendemmia</label>
                                <Select
                                    options={vendemmiaOperationUnitValues}
                                    name="registration.vendemmiaOperationUnit"
                                    isMulti
                                    className="outline-none transform-none text-black"
                                    placeholder="Selecione a filial Vendemmia"
                                    value={
                                        values.registration && values.registration.vendemmiaOperationUnit
                                        ? {
                                            value: values.registration.vendemmiaOperationUnit,
                                            label: values.registration.vendemmiaOperationUnit
                                        }
                                        : null
                                    }
                                    onChange={value =>
                                        value &&
                                        setFieldValue(
                                            "registration.vendemmiaOperationUnit",
                                            value.map(item => item.value)
                                        )
                                    }
                                />
                                <ErrorMessage
                                    component="p"
                                    name="registration.vendemmiaOperationUnit"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="text-roxo_oficial font-bold mt-4">
                                Tipo de Operação <small>(selecione todos os tipos a serem operados)</small>
                            </label>
                            <Select
                                options={operationTypeValues}
                                name="registration.operationType"
                                className="outline-none transform-none text-black"
                                isMulti
                                placeholder="Selecione o tipo de operação"
                                value={
                                    values.registration && values.registration.operationTypeValues
                                    ? {
                                        value: values.registration.operationTypeValues,
                                        label: values.registration.operationTypeValues
                                    }
                                    : null
                                }
                                onChange={value =>
                                    value &&
                                    setFieldValue(
                                        "registration.operationType",
                                        value.map(item => item.value)
                                    )
                                }
                            />
                            <ErrorMessage
                                component="p"
                                name="registration.operationType"
                                className="text-red text-xs font-light w-full"
                            />
                        </div>
                        <div>
                            <label htmlFor="companyName" className="text-roxo_oficial font-bold mt-4">
                                Razão Social do Cliente
                            </label>
                            <Input
                                name="registration.companyName"
                                id="companyName"
                                onChange={handleChange}
                                placeholder="Preencha a razão social"
                            />
                            <ErrorMessage
                                component="p"
                                name="registration.companyName"
                                className="text-red text-xs font-light w-full"
                            />
                        </div>
                        <div>
                            <label htmlFor="companyFantasyName" className="text-roxo_oficial font-bold mt-4">
                                Nome fantasia do Cliente
                            </label>
                            <Input
                                name="registration.companyFantasyName"
                                id="companyFantasyName"
                                onChange={handleChange}
                                placeholder="Preencha o nome fantasia"
                            />
                            <ErrorMessage
                                component="p"
                                name="registration.companyFantasyName"
                                className="text-red text-xs font-light w-full"
                            />
                        </div>
                        {inputCompanyBranches.map((_, index) => (
                            <div className="flex" key={index}>
                                <div className="w-1/2 pr-2">
                                    <label htmlFor={`companyBranches${index}`} className="text-roxo_oficial mt-4 font-bold">
                                        CNPJ do Cliente
                                    </label>
                                    <InputMasked
                                        name={`companyBranches[${index}].companyOperationDocumentNumber`}
                                        id={`companyBranches${index}`}
                                        handleChange={e =>
                                            handleInputChange(
                                                index,
                                                setFieldValue,
                                                values.registration,
                                                e.target.value,
                                                "companyBranches",
                                                "companyOperationDocumentNumber",
                                                "registration"
                                            )
                                        }
                                        value={
                                            values.registration && values.registration.companyOperationDocumentNumber
                                            ? {
                                                value: values.registration.companyOperationDocumentNumber,
                                                label: values.registration.companyOperationDocumentNumber
                                            }
                                            : null
                                        }
                                        defaultValue={values.registration.companyBranches[index]?.companyOperationDocumentNumber}
                                        value={values.registration.companyBranches[index]?.companyOperationDocumentNumber}
                                        mask="99.999.999-9999/99"
                                    />
                                </div>
                                <div className="w-1/2 mt-3 pl-2">
                                    <label htmlFor={`subscriptionState${index}`} className="text-roxo_oficial font-bold">
                                        Inscrição Estadual
                                    </label>
                                    <Field
                                        name={`companyBranches[${index}].subscriptionState`}
                                        className="w-full rounded-md border py-1 px-2 border-C2 h-9"
                                        id={`subscriptionState${index}`}
                                        onChange={e =>
                                            handleInputChange(
                                                index,
                                                setFieldValue,
                                                values.registration,
                                                e.target.value,
                                                "companyBranches",
                                                "subscriptionState",
                                                "registration"
                                            )
                                        }
                                        value={values.registration.companyBranches[index]?.subscriptionState}
                                        placeholder="Preencha a inscrição estadual"
                                    />
                                </div>
                                {inputCompanyBranches.length > 1 && (
                                    <div className="flex items-end pl-4">
                                        <button
                                            onClick={() =>
                                                handleRemoveChange(
                                                    setFieldValue,
                                                    index,
                                                    values.registration.companyBranches,
                                                    "companyBranches",
                                                    inputCompanyBranches,
                                                    setInputCompanyBranches,
                                                    "registration"
                                                )
                                            }
                                            className=" flex items-center justify-center bg-vermelho_1 text-vermelho_3 border rounded-md border-vermelho_2 h-9 w-10 focus:outline-none hover:bg-vermelho_2 hover:transition duration-200">
                                            <GoTrashcan />
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}{" "}
                        <div>
                            <ErrorMessage
                                component="p"
                                name={`registration.companyBranches`}
                                className="text-red text-xs font-light w-full"
                            />
                            <button
                                onClick={() => handleAddClick(inputCompanyBranches, setInputCompanyBranches)}
                                className="flex gap-1 bg-verde_1 w-1/3 justify-center text-verde_2 py-2 px-5 rounded-md mt-4 border border-verde_2 border-dashed items-center focus:outline-none hover:bg-verde_2 hover:text-verde_3 hover:transition duration-200 hover:border-verde_3"
                                type="button">
                                <BsPlus />
                                Adicionar CNPJ
                            </button>
                        </div>
                        <div className="flex mt-4">
                            <div className="w-1/2 pr-2">
                                <label htmlFor="companyOperation" className="text-roxo_oficial font-bold">
                                    Selecione o CNPJ principal da Operação do Cliente <small>(cadastre todos acima)</small>
                                </label>
                                <Select
                                    options={companyOperationSelectTransformer(values.registration.companyBranches)}
                                    name="registration.companyOperationDocumentNumber"
                                    className="outline-none transform-none text-black"
                                    placeholder="Selecione o CNPJ"
                                    onChange={value => {
                                        setFieldValue("registration.companyOperationDocumentNumber", value.companyOperationDocumentNumber);
                                        setFieldValue(
                                            "registration.companyOperationSubscriptionState",
                                            value.subscriptionState
                                        );
                                    }}
                                />
                                <ErrorMessage
                                    component="p"
                                    name="registration.companyOperationDocumentNumber"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                            <div className="w-1/2 pl-2">
                                <label className="text-roxo_oficial font-bold">Inscrição Estadual</label>
                                <Input
                                    name="registration.companyOperationSubscriptionState"
                                    disabled
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    component="p"
                                    name="registration.companyOperationSubscriptionState"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationDataForm;
